import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";
import { baseURL } from "../../App";
import axios from "axios";


const Footer = () => {


    const [footer, setFooter] = useState([]);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/footer?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setFooter(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    const [footertitles, setFootertitles] = useState([]);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/footer_titles?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setFootertitles(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    const [menuItems, setMenuItems] = useState([]);


    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const response = await axios.get(`${baseURL}items/header?fields=*.*`)
                if (response.data && Array.isArray(response.data.data)) {
                    setMenuItems(response.data.data);
                } else {
                    console.error("Invalid data format received from Directus.");
                }
            } catch (error) {
                console.error("Error fetching data from Directus:", error);
            }
        };

        fetchMenuItems();
    }, []);



    const [contact, setContact] = useState([]);



    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/contact?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setContact(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);


    const [genaralsettings, setgenaralsettings] = useState(null);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/genaral_settings?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setgenaralsettings(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);


    const [icons, seticons] = useState(null);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/social_icons?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        seticons(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    return (
        <footer
            className="footer-section"
        >
            <div className="footer-top position-relative">
                {/* <img
                    className="footer-shape"
                    src={process.env.PUBLIC_URL + "/images/footer/1.png"}
                    alt="shape"
                /> */}
                <div className="container"
                    style={{
                        boxShadow: "0 -8px 6px -6px rgba(255, 255, 255, 0.4)"
                    }}>
                    <div className="row mb-n7">
                        <div className="col-lg-3 col-sm-6 mb-7 mt-5">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-9"
                                    image={`${baseURL}assets/${genaralsettings?.logo?.id}`}
                                />

                                <ul className="footer-social-links">
                                    {icons &&
                                        icons.map((e) => (
                                            <li key={e.id}>
                                                <a
                                                    className="footer-link"
                                                    href={e?.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i
                                                        className={`${e?.icon}`}
                                                        style={{ fontSize: "24px", color: e?.color }}
                                                        onMouseEnter={(event) => (event.target.style.color = "#59e3a7")}
                                                        onMouseLeave={(event) => (event.target.style.color = e?.color)}
                                                    />
                                                </a>
                                            </li>
                                        ))}
                                </ul>


                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mb-7 offset-xl-1 mt-5">
                            <div className="footer-widget">
                                <h4 className="title">
                                    <Link to={footertitles?.menu_link} >
                                        {footertitles?.menu_title}
                                    </Link>
                                </h4>
                                <ul className="footer-menu">
                                    {menuItems && menuItems.map((e) => (
                                        <li key={e.id}>
                                            <Link
                                                className="footer-link"
                                                to={e?.link}
                                            >
                                                {e?.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 mb-7 mt-5">
                            <div className="footer-widget">
                                <h4 className="title">
                                    <Link to={footertitles?.menu2_link} >
                                        {footertitles?.menu2_title}
                                    </Link>
                                </h4>
                                <ul className="footer-menu">
                                    {footer && footer.map((e) => (
                                        <li key={e.id}>
                                            <Link
                                                className="footer-link"
                                                to={e.link}
                                            >
                                                {e.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 mb-7 mt-5">
                            <div className="footer-widget">
                                <h4 className="title">
                                    <Link to={footertitles?.contact_link} >
                                        {footertitles?.contact_title}
                                    </Link>
                                </h4>

                                <p>{contact?.address}</p>
                                <ul className="address">
                                    <li>
                                        <a href={`tel:${contact?.phone_link}`} className="address-link"
                                        >
                                            {contact?.phone_text}
                                        </a>
                                    </li>

                                    <li>
                                        <a href={`tel:${contact?.mail_link}`} className="address-link"
                                        >
                                            {contact?.mail_text}
                                        </a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-info text-center">
                                <p>
                                    {genaralsettings?.copyright}{" "}
                                    <a
                                        href={genaralsettings?.copyright_link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {genaralsettings?.copyright_text}
                                    </a>{" "}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
