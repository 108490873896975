import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { baseURL } from "../../App";
import axios from "axios";

const ContactForm = () => {
    const { register, errors } = useForm({
        mode: "onBlur",
    });

    const [contact, setcontact] = useState(null);

    useEffect(() => {
        const getinfo = async () => {
            const inf = await axios.get(`${baseURL}items/contact?fields=*.*`);
            setcontact(inf.data.data);
        };
        getinfo();
    }, []);

    const [mesajData, setmesajData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const postMessage = async () => {
        try {
            const res = await axios.post(`${baseURL}items/messages`, mesajData);
            if (res.status === 200 || res.status === 201) {
                alert("Form submitted! ");
                setmesajData({
                    name: "",
                    email: "",
                    message: "",
                });
            } else {
                alert("An error occurred. Please try again.");
            }
        } catch (error) {
            alert("An error occurred. Please try again.");
        }
    };

    const handleChange = (e) => {
        setmesajData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                onSubmit={(e) => {
                    e.preventDefault();
                    postMessage();
                }}
            >
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={mesajData.name}
                        onChange={handleChange}
                        placeholder={contact?.form_name}
                        ref={register({ required: "Name is required" })}
                        style={{ color: "black" }}
                    />
                    {errors?.name && <p>{errors.name.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        value={mesajData.email}
                        onChange={handleChange}
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder={contact?.form_mail}
                        ref={register({
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                            },
                        })}
                        style={{ color: "black" }}
                    />
                    {errors?.email && <p>{errors.email.message}</p>}
                </div>

                <div className="col-12 mb-9">
                    <textarea
                        value={mesajData.message}
                        onChange={handleChange}
                        className="form-control massage-control"
                        name="message"
                        id="massage"
                        cols="30"
                        rows="10"
                        placeholder={contact?.form_mesaj}
                        ref={register({
                            required: "Message is required",
                        })}
                        style={{ color: "black" }}
                    ></textarea>
                    {errors?.message && <p>{errors.message.message}</p>}
                </div>
                <div className="col-12">
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                        data-complete-text="Well Done!"
                    >
                        {contact?.form_button || "Submit"}
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default ContactForm;
