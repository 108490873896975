import React, { useState, useEffect } from "react";
import { baseURL } from "../App";
import axios from "axios";

import ProgressBar from "react-bootstrap/ProgressBar";
import Input from "./Input";
import info from "../info";
import Currency from "./Currency";
import { Card } from "react-bootstrap";




import {
    useAccount,
    useWaitForTransactionReceipt,
    useWriteContract,
} from "wagmi";
import { ConnectKitButton } from "connectkit";
import {
    addDecimal,
    getAllowance,
    getHardcap,
    getRate,
    getRefPercent,
    getTokenAmount,
    getTokensSold,
    getUsersBought,
    getWeiRaised,
    removeDecimal,
} from "../hooks/wagmiHooks";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";
import { erc20Abi } from "viem";
import { config } from "../App";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { presaleAbi } from "../abi/presaleAbi";
import { BigNumber } from "bignumber.js";
import useWindowHeight from "../useWindowHeight";
import Loading from "./Loading";
import { Link } from "react-router-dom";



function Presaleyeni() {


    const [barwidth, setBarwidth] = useState(0);
    const [chosenPayment, setchosenPayment] = useState(info.tokens[0]);
    const [firstinput, setfirstinput] = useState("");
    const [secondinput, setsecondinput] = useState(0);
    const [totalRaised, settotalRaised] = useState(0);
    const { address, isConnected } = useAccount();
    const [usersBuy, setusersBuy] = useState(0);
    const [approveNeeded, setApproveNeeded] = useState(true);
    const [usdtPrice, setusdtPrice] = useState(0);
    const [tokensSold, settokensSold] = useState(0);
    const [hardcap, sethardcap] = useState(0);
    const height = useWindowHeight();

    const { data: hash, writeContract, error: writeError } = useWriteContract();




    useEffect(() => {
        if (writeError) {
            console.log(writeError);
            toast.error("Some Error Happened");
        }
    }, [writeError]);

    const { isLoading: isConfirming, isSuccess: isConfirmed } =
        useWaitForTransactionReceipt({
            hash,
        });

    useEffect(() => {
        const getApproveInfo = async () => {
            if (address && isConnected && firstinput && !chosenPayment.native) {
                const allowance = await getAllowance(address, chosenPayment.address);
                if (allowance >= Number(firstinput)) {
                    setApproveNeeded(false);
                } else {
                    setApproveNeeded(true);
                }
            } else if (chosenPayment.native) {
                setApproveNeeded(false);
            }
        };
        getApproveInfo();
    }, [chosenPayment, firstinput, isConfirmed]);

    useEffect(() => {
        async function getWeiRaised1() {
            const rate = await getWeiRaised();
            settotalRaised(rate);
            const getTokens = await getTokenAmount(
                info.usdtAddress,
                addDecimal(1, 18)
            );
            const usdt = 1 / Number(getTokens).toFixed(0);
            setusdtPrice(usdt.toFixed(4));
            const hardc = await getHardcap();
            const tokensSold = await getTokensSold();
            sethardcap(hardc);
            settokensSold(tokensSold);
            setBarwidth((Number(tokensSold) / Number(hardc)) * 100);
        }
        getWeiRaised1();
    }, [isConfirmed]);

    useEffect(() => {
        const getuserinfo = async () => {
            if (address && isConnected) {
                const userbuyamount = await getUsersBought(address);
                console.log(userbuyamount, "usersBuy");
                setusersBuy(userbuyamount);
            }
        };
        getuserinfo();
    }, [address, isConnected, isConfirmed]);

    useEffect(() => {
        const calculateTokens = async () => {
            if (!firstinput || firstinput == 0) {
                setsecondinput(0);
            } else {
                const getTokens = await getTokenAmount(
                    chosenPayment.address,
                    addDecimal(firstinput, 18, 0)
                );
                setsecondinput(getTokens);
            }
        };
        calculateTokens();
    }, [firstinput, chosenPayment]);

    const approveTx = async () => {
        try {
            writeContract({
                abi: erc20Abi,
                address: chosenPayment.address,
                functionName: "approve",
                args: [info.contractAddress, addDecimal(firstinput, 18)],
            });
        } catch (error) {
            toast.error("Error: Transaction couldn't sent to the blockchain");
        }
    };

    const processTx = async () => {
        if (approveNeeded) {
            approveTx();
        } else {
            if (chosenPayment.native) {
                writeContract({
                    abi: presaleAbi,
                    address: info.contractAddress,
                    functionName: "buyWithBnb",
                    args: [addDecimal(firstinput, 18)],
                    value: addDecimal(firstinput, 18),
                });
            } else {
                writeContract({
                    abi: presaleAbi,
                    address: info.contractAddress,
                    functionName: "buyTokens",
                    args: [addDecimal(firstinput, 18), chosenPayment.address],
                });
            }
        }
    };


    const [settings, setSettings] = useState([]);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/settings?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setSettings(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    const [presale, setpresale] = useState([]);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/presale?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setpresale(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);


    return (

        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginTop: "35px"
            }}
        >


            <div className="container mt-5 mb-5 position-relative">
                <div className="row pb-5 mt-5">

                    <div
                        className="col-md-6 px-md-5"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Card
                            style={{
                                border: "2px solid #59e3a7",
                                backgroundColor: "#000",
                                color: "black",
                                borderRadius: "15px",
                                padding: "20px",
                            }}
                        >
                            <Card.Body>

                                <h5 className="text-small text-center">
                                    {
                                        <>
                                            <span className="bold-text text-white">
                                                {presale?.purchase}{info.buyToken.symbol} =
                                            </span>{" "}
                                            <>{!isConnected ? "0" : usersBuy}</>
                                        </>
                                    }
                                </h5>
                                <div className="row mt-2">
                                    <div className="col-3">
                                        <hr
                                            className="lined"
                                            style={{
                                                float: "left",
                                                width: "100%",
                                                borderColor: info.colors.header,
                                            }}
                                        />
                                    </div>
                                    <div className="col-6  text-center">
                                        <h5
                                            className="text-small mt-2 text-white"
                                            style={{ fontSize: "18px" }}
                                        >
                                            {" "}
                                            1 {info.buyToken.symbol} =
                                            <span
                                                className="text-small mt-2 "
                                                style={{ fontSize: "18px", color: "#59e3a7" }}
                                            >
                                                {" "}
                                                ${usdtPrice}
                                            </span>
                                        </h5>

                                    </div>
                                    <div className="col-3">
                                        <hr
                                            className="lined"
                                            style={{
                                                float: "right",
                                                width: "100%",
                                                borderColor: info.colors.header,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2 mb-3 p-0">
                                    <h5 className="text-center text-white">Pay With</h5>
                                    {info.tokens.map((e) => (
                                        <div
                                            className="col-4 m-0 p-1"
                                            onClick={() => setchosenPayment(e)}
                                            key={e.symbol}
                                        >
                                            <Currency token={e} chosenPayment={chosenPayment} />
                                        </div>
                                    ))}
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <p className="text-small" style={{ fontWeight: "bold", color: "white" }}>
                                            Amount in {chosenPayment && chosenPayment.symbol}
                                        </p>
                                        <Input
                                            token={chosenPayment}
                                            value={firstinput}
                                            setValue={setfirstinput}

                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <p className="text-small" style={{ fontWeight: "bold", color: "white" }}>
                                            Receive {info.buyToken.symbol}{" "}
                                        </p>
                                        <Input
                                            token={info.buyToken}
                                            disabled={true}
                                            value={secondinput}
                                            setValue={setsecondinput}
                                        />
                                    </div>
                                    <div
                                        className="col-12 mt-3"
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {!isConnected && (
                                            <ConnectKitButton.Custom>
                                                {({ show, hide, address, ensName, chain }) => {
                                                    return (
                                                        <button className="button-custom text-white btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0" onClick={show}>
                                                            CONNECT WALLET
                                                        </button>
                                                    );
                                                }}
                                            </ConnectKitButton.Custom>
                                        )}
                                        {address && isConnected && !isConfirming && (
                                            <button className="button-custom text-white btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0" onClick={processTx}>
                                                {approveNeeded
                                                    ? "APPROVE"
                                                    : "BUY " + info.buyToken.symbol}
                                            </button>
                                        )}
                                        {isConfirming && (
                                            <button className="button-custom text-white btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0">
                                                <Spinner />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>


                    <div className="col-md-6 mt-3 mb-3">
                        <div className="row mt-5">
                            <h3 className=" mb-5">{presale?.title}</h3>
                            <h2>{presale?.text}</h2>
                            <p>{presale?.content}</p>
                            <div className="col-md-4 mt-3">
                                <div
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={`${baseURL}assets/${presale.img?.id}`} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div
                className="container text-center"
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                        className="mt-3 mb-3"
                    >

                    </div>
                    <h4
                        style={{
                            fontWeight: "lighter",
                            color: info.colors.second,
                            fontSize: "17px",
                            marginBottom: "50px",
                        }}
                        className="mt-4"
                    >
                    </h4>
                </div>
            </div>
        </div>

    );
};

export default Presaleyeni;
