import { useState, useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";
import { baseURL } from "../../App";
import axios from "axios";



const AboutArea = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);



    const [certified, setCertified] = useState([]);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/certified?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setCertified(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);




    const [aboutpage, setAboutpage] = useState([]);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/about_page?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setAboutpage(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div>
                                <img
                                    src={`${baseURL}assets/${aboutpage.vision_img?.id}`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div className="about-content">
                            <span className="date-meta">{`${aboutpage?.vision_title} `}</span>
                            <p className="mb-5">{`${aboutpage?.vision_text} `}</p>

                        </div>
                    </div>

                </div>


                <br /><br />




                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">

                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div>
                                <img
                                    src={`${baseURL}assets/${aboutpage.mission_img?.id}`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">

                        <div className="about-content">
                            <span className="date-meta">{`${aboutpage?.mission_title} `}</span>
                            <p className="mb-5">{`${aboutpage?.mission_text} `}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AboutArea;
