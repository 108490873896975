import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AboutContainer from "../containers/about/index";
import AboutArea from "../containers/about/page";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import TestimonialReverse from "../containers/global/testimonial-reverse";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { baseURL } from "../App";
import axios from "axios";
import Loading from "../components/Loading";



const AboutPage = () => {


    const [aboutpage, setAboutpage] = useState(null);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/about_page?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setAboutpage(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    const [settings, setSettings] = useState(null);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/settings?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setSettings(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    if (aboutpage === null || settings === null) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            <Layout>
                <SEO title={`${aboutpage?.seo_title} `} />
                <div className="wrapper">
                    <Header />

                    <PageBanner
                        title={`${aboutpage?.banner_title} `}
                        excerpt={`${aboutpage?.banner_text} `}
                        image={`${baseURL}assets/${aboutpage?.banner_img?.id}`}
                    />

                    <br />
                    {settings?.vision_mission_active === true ? (
                        <AboutArea />
                    ) : (
                        "")}

                    {settings?.about_intro_active === true ? (
                        <IconBoxContainer classOption="section-pt" />
                    ) : (""
                    )}

                    <br />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default AboutPage;
