import React, { useState, useEffect } from "react";
import { baseURL } from "../App";
import axios from "axios";
import { Helmet } from "react-helmet";

function HelmetAll() {
    const [genaralsettings, setgenaralsettings] = useState([]);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${baseURL}items/genaral_settings?fields=*.*`);
                if (response.data) {
                    setgenaralsettings(response.data.data);
                } else {
                    console.error("Invalid data format received from Directus.");
                }
            } catch (error) {
                console.error("Error fetching data from Directus:", error);
            }
        };

        fetchSettings();
    }, []);



    return (
        <div className="application">
            <Helmet>
                <link rel="shortcut icon" href={baseURL + "assets/" + genaralsettings?.favicon?.id} type="image/png" />
            </Helmet>

        </div>
    );
};

export default HelmetAll