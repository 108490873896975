import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ContactContainer from "../containers/contact";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { baseURL } from "../App";
import axios from "axios";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";



const NotFound = () => {
    const [not, setNot] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${baseURL}items/not_found?fields=*.*`);
                if (response.data) {
                    setNot(response.data.data);
                } else {
                    console.error("Invalid data format received from Directus.");
                }
            } catch (error) {
                console.error("Error fetching data from Directus:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    if (loading) {
        return <Loading />;
    }


    return (
        <React.Fragment>
            <Layout>
                <SEO title="AirDiamond – Contact" />
                <div className="wrapper">
                    <Header />
                    {/* <PageBanner
                        title={not?.banner_title}
                        excerpt={not?.banner_text}
                        image={`${baseURL}assets/${not?.banner_img?.id}`}
                    /> */}
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "50px",
                        marginTop:"150px"
                    }}>
                        <h2>{not?.banner_title}</h2>
                        <p >{not?.title} <Link to="/">{not?.text}</Link>.</p>
                        <img
                            src={`${baseURL}assets/${not.img?.id}`}
                            alt="about"
                            className="mt-8 mb-4"
                            style={{
                                width: "400px"
                            }}
                        />

                    </div>

                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default NotFound;
