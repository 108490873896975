import usdt from "./assets/usdt.png";
import bnb from "./assets/bnb.png";
import tokenImage from "./assets/logo.png";
import usdc from "./assets/usdc.webp";
import eth from "./assets/eth.webp";
import shiba from "./assets/shiba.webp";
import cardano from "./assets/cardano.webp";

const info = {
  contractAddress: "0x79cD81091fb227b9b8E3C59fc84F587c75Efd23B",
  usdtAddress: "0x55d398326f99059fF775485246999027B3197955",
  tokenDecimal: 18,
  refPercent: 10,
  referral: false,
  tokens: [
    {
      name: "Tether USD",
      symbol: "USDT",
      image: usdt,
      baseToken: true,
      address: "0x55d398326f99059fF775485246999027B3197955",
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      image: bnb,
      native: true,
      address: "0x0000000000000000000000000000000000000000",
    },
    {
      name: "Binance USDC",
      symbol: "USDC",
      image: usdc,
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    },
    {
      name: "Binance ETH",
      symbol: "ETH",
      image: eth,
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    },
    {
      name: "Binance SHIBA",
      symbol: "SHIBA",
      image: shiba,
      address: "0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
    },
    {
      name: "Binance Cardano",
      symbol: "ADA",
      image: cardano,
      address: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
    },
  ],
  buyToken: {
    name: "Airdiamond Token",
    symbol: "ADO",
    image: tokenImage,
  },
  colors: {
    first: "#ffffff",
    second: "#9aa7bc",
    header: "#fff",
    backgroundColor: "#000",
  },
};

export default info;
