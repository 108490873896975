import React, { useState, useEffect } from "react";

import ContactForm from "../../components/contact-form";
import ContactInfo from "../../components/contact-info";
import ContactData from "../../data/contact.json";

import { baseURL } from "../../App";
import axios from "axios";


const ContactContainer = () => {


    const [contact, setContact] = useState([]);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/contact?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setContact(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    return (
        <section className="contact-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-6 col-lg-6 mb-7">
                        {contact && contact && (
                            <div className="contact-title-section">
                                <h3 className="title" style={{ color: "#59e3a7" }}>{`${contact?.title} `}</h3>
                                <p style={{ color: "white" }}>
                                    {`${contact?.text} `}
                                    <br className="d-none d-xl-block" />

                                </p>
                            </div>
                        )}

                        <ContactForm />
                    </div>

                    <div className="col-xl-5 col-lg-6 mb-9 offset-xl-1">
                        <div className="contact-address text-center">
                            <div className="address-list mb-4 mb-xl-10 pb-2">
                                <h4 className="title">{contact?.address}</h4>
                                <p style={{ color: "black" }}>{contact?.address1}</p>

                                <h4 className="title">
                                        {contact?.phone}
                                </h4>
                                <p style={{ color: "black" }}>
                                    <a href={`tel:${contact?.phone_link}`} style={{ color: "black" }}>
                                        {contact?.phone_text}
                                    </a>
                                </p>

                                <h4 className="title">
                                        {contact?.mail}
                                </h4>
                                <p style={{ color: "black" }}>
                                    <a href={`mailto:${contact?.mail_link}`} style={{ color: "black" }}>
                                        {contact?.mail_text}
                                    </a>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactContainer;
