import React, { useState, useEffect } from "react";
import Accordion, {
    AccordionItem,
    AccordionTitle,
    AccordionContent,
} from "../accrodion";
import { baseURL } from "../../App";
import axios from "axios";


const AccordionWrap = () => {


    const [faq, setFaq] = useState([]);



    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/faq?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setFaq(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);


    return (
        <Accordion classOption="accordion-style2 no-bg">
            {faq.map((e, index) => (
                <AccordionItem key={index} id={`accordion-${index}`}>
                    <AccordionTitle id={`title-${index}`}>
                        <span className="question-tag">Q:</span> {e.question}
                    </AccordionTitle>
                    <AccordionContent id={`content-${index}`}>
                        {e.answer}
                    </AccordionContent>
                </AccordionItem>
            ))}
        </Accordion>
    );
};

export default AccordionWrap;
