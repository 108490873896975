import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BlogGrid from "../../../components/blog";
import { baseURL } from "../../../App";
import axios from "axios";
import { Link } from "react-router-dom";


const BlogItemContainer = ({ data }) => {



    const formattedDate = (originalDateString) => {
        const originalDate = new Date(originalDateString);

        const day = originalDate.getDate().toString().padStart(2, "0");
        const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz.
        const year = originalDate.getFullYear();

        return `${day}.${month}.${year}`;
    };

    // const [data, setdata] = useState(null);
    const [blogs, setblogs] = useState(null);
    const [query, setquery] = useState("");
    const [page, setpage] = useState(1);
    const [lastPosts, setlastPosts] = useState(null);
    const [count, setcount] = useState(0);




    useEffect(() => {
        const getinfo = async () => {
            const blgs = await axios.get(
                `${baseURL
                }items/blogs?fields=*.*&limit=2&page=${page}&filter[status]=published&search=${query.toLowerCase()}`
            );
            setblogs(blgs.data.data);
            const blgcount = await axios.get(
                `${baseURL
                }items/blogs?fields=*.*&filter[status]=published&search=${query.toLowerCase()}&aggregate[count]=*`
            );
            setcount(blgcount.data.data[0].count / 2);
        };
        getinfo();
    }, [query, page]);


    const handlePageClick = (event) => {
        setpage(event.selected + 1);
    };




    return (
        <section className="blog-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    {/* {data && data.map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col-lg-4 col-sm-6 mb-7"
                                >
                                    <BlogGrid
                                        classOption="p-0"
                                        key={key}
                                        data={single}
                                    />
                                </div>
                            );
                        })} */}

                    {blogs?.length > 0 ? (
                        blogs.map((blog, i) => {
                            return (
                                <div
                                    key={i}
                                    className="col-lg-4 col-sm-6 mb-7"
                                >
                                    {/* <BlogGrid
                                    classOption="p-0"
                                    key={key}
                                    data={single}
                                /> */}


                                    <div className="blog-card">
                                        <div className={`thumb bg-light text-center `}>
                                            <Link to={`/blogs/${blog?.blog_link}`}>
                                                {/* <img
                                                src={`${process.env.PUBLIC_URL}/${data.media.gridImage}`}
                                                alt="img"
                                            /> */}
                                                link içindeki yazı
                                            </Link>
                                        </div>
                                        <div className="blog-content">
                                            <Link to={`/blogs/${blog?.blog_link}`}>
                                                <span className="blog-meta author">link</span>
                                            </Link>
                                            <span className="separator">-</span>
                                            <Link
                                                to={blog?.date_created &&
                                                    formattedDate(blog?.date_created)}
                                            >
                                                <span className="blog-meta date">{blog?.date_created &&
                                                    formattedDate(blog?.date_created)}</span>
                                            </Link>
                                            <h3 className="title">
                                                <Link
                                                    to={process.env.PUBLIC_URL + `/blog-details/${blog.id}`}
                                                >
                                                    {blog.title}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <h5>
                            {
                                data?.translations?.filter(
                                    (c) => c.languages_code == globalState.lang
                                )[0]?.blog_bulunamadi_yazisi
                            }
                        </h5>
                    )}
                </div>
            </div>
        </section>
    );
};

BlogItemContainer.propTypes = {
    data: PropTypes.array,
};

export default BlogItemContainer;
