import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { slugify } from "../../utils";
import { baseURL } from "../../App";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";


const formattedDate = (originalDateString) => {
    const originalDate = new Date(originalDateString);

    const day = originalDate.getDate().toString().padStart(2, "0");
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz.
    const year = originalDate.getFullYear();

    return `${day}.${month}.${year}`;
};



const BlogGrid = ({ data, classOption }) => {





    const [item, setitem] = useState(null);
    const [error, seterror] = useState(null);

    const { url } = useParams();




    useEffect(() => {
        const getData = async () => {
            try {
                var dat = await axios.get(
                    `${baseURL}items/blogs?fields=*.*&filter[blog_link]=${url}`
                );
                if (dat.data.data.length > 0) {
                    setitem(dat.data.data[0]);
                } else {
                    seterror(true);
                }
            } catch (error) {
                seterror(true);
            }
        };
        getData();
    }, [url]);


    return (
        <div className="blog-card">
            <div className={`thumb bg-light text-center ${classOption}`}>
                <Link to={`/blog/${item?.blog_link}`}>
                    <img
                        src={`${process.env.PUBLIC_URL}/${data.media.gridImage}`}
                        alt="img"
                    />
                </Link>
            </div>
            <div className="blog-content">
                <Link
                    to={
                        process.env.PUBLIC_URL +
                        `/author/${slugify(data.author)}`
                    }
                >
                    <span className="blog-meta author">{data.author} bu hangi sayfa</span>
                </Link>
                <span className="separator">-</span>
                <Link
                    to={process.env.PUBLIC_URL + `/date/${slugify(data.date)}`}
                >
                    <span className="blog-meta date">{data.date} tarihsdg</span>
                </Link>
                <h3 className="title">
                    <Link
                        to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}
                    >
                        {data.title}
                    </Link>
                </h3>
            </div>
        </div>
    );
};

// BlogGrid.propTypes = {
//     data: PropTypes.object,
//     classOption: PropTypes.string,
// };

// BlogGrid.defaultProps = {
//     classOption: "thumb",
// };

export default BlogGrid;
