import React, { useState, useEffect } from "react";
import SectionTitle from "../../../components/section-title";
import ServiceBox from "../../../components/service-box";
import ServiceData from "../../../data/service.json";
import { baseURL } from "../../../App";
import axios from "axios";
import { Link } from "react-router-dom";


const ServiceListContainer = () => {


    const [features, setFeatures] = useState([]);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/features?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setFeatures(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);




    return (
        <div className="service-section section-pt position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-8 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 pb-10 text-center"
                            subTitle=""
                            title={`${features?.title} `}
                            excerptClassOption="null"
                            excerpt={`${features?.text} `}
                        />
                    </div>
                </div>

                <div className="row mb-n7 align-items-center">
                    <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-left">
                            {/* {ServiceData &&
                                ServiceData.slice(0, 3).map((single, key) => {
                                    return (
                                        <ServiceBox data={single} key={key} />
                                    );
                                })} */}

                            <div className="service-media">
                                {/* <img
                                    className="logo"
                                    src={`${baseURL}assets/${features.img1?.id}`}
                                    alt=" service logo"
                                    width={"250px"}

                                /> */}
                                <div className="service-media-body">
                                    <h4 className="title" style={{ color: "#59e3a7 " }}>
                                        {/* <Link
                                            to={`${features?.content1} `}
                                        >
                                            {`${features?.content1} `}
                                        </Link> */}
                                        {`${features?.content1} `}
                                    </h4>
                                    <p>{features.text1}</p>
                                </div>
                            </div>
                            <div className="service-media">
                                {/* <img
                                    className="logo"
                                    src={`${baseURL}assets/${features.img2?.id}`}
                                    alt=" service logo"
                                    width={"250px"}

                                /> */}
                                <div className="service-media-body">
                                    <h4 className="title" style={{ color: "#59e3a7 " }}>
                                        {`${features?.content2} `}
                                    </h4>
                                    <p>{features.text2}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 mb-7 order-md-1 order-xl-0">
                        <div className="service-media-img text-center">
                            <img
                                src={`${baseURL}assets/${features.center_img?.id}`}
                                alt="images_not_found"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-right">
                            {/* {ServiceData &&
                                ServiceData.slice(3, 5).map((single, key) => {
                                    return (
                                        <ServiceBox data={single} key={key} />
                                    );
                                })} */}
                            <div className="service-media">
                                {/* <img
                                    className="logo"
                                    src={`${baseURL}assets/${features.img3?.id}`}
                                    alt=" service logo"
                                    width={"250px"}
                                /> */}
                                <div className="service-media-body">
                                    <h4 className="title" style={{ color: "#59e3a7 " }}>

                                        {`${features?.content3} `}
                                    </h4>
                                    <p>{features.text3}</p>
                                </div>
                            </div>

                            <div className="service-media">
                                {/* <img
                                    className="logo"
                                    src={`${baseURL}assets/${features.img4?.id}`}
                                    alt=" service logo"
                                    width={"250px"}

                                /> */}
                                <div className="service-media-body">
                                    <h4 className="title" style={{ color: "#59e3a7 " }}>
                                        {`${features?.content4} `}
                                    </h4>
                                    <p>{features.text4}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceListContainer;
