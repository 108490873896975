import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Loading from "../components/Loading";
import ContactContainer from "../containers/contact";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { baseURL } from "../App";
import axios from "axios";


const ContactPage = () => {


    const [contact, setContact] = useState(null);
    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        const fetchContactData = async () => {
            try {
                const response = await axios.get(`${baseURL}items/contact?fields=*.*`);
                if (response.data) {
                    setContact(response.data.data);
                } else {
                    console.error("Invalid data format received from Directus.");
                }
            } catch (error) {
                console.error("Error fetching data from Directus:", error);
            }
        };

        const fetchSettingsData = async () => {
            try {
                const response = await axios.get(`${baseURL}items/settings?fields=*.*`);
                if (response.data) {
                    setSettings(response.data.data);
                } else {
                    console.error("Invalid data format received from Directus.");
                }
            } catch (error) {
                console.error("Error fetching data from Directus:", error);
            }
        };


        fetchContactData(); fetchSettingsData();
    }, []);



    if (contact === null || settings === null) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            <Layout>
                <SEO title={contact?.seo_title} />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={contact?.banner_title}
                        excerpt={contact?.banner_text}
                        image={`${baseURL}assets/${contact.img?.id}`}
                    />
                    <ContactContainer /> <br />

                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ContactPage;
