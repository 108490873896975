import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AboutContainer from "../containers/about";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import TestimonialReverse from "../containers/global/testimonial-reverse";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { baseURL } from "../App";
import axios from "axios";
import Loading from "../components/Loading";



const Certified = () => {


    const [certified, setCertified] = useState(null);
    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCertified = async () => {
            try {
                const response = await axios.get(`${baseURL}items/certified?fields=*.*`);
                if (response.data) {
                    setCertified(response.data.data);
                } else {
                    console.error("Invalid data format received from Directus.");
                }
            } catch (error) {
                console.error("Error fetching certified data from Directus:", error);
            }
        };

        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${baseURL}items/settings?fields=*.*`);
                if (response.data) {
                    setSettings(response.data.data);
                } else {
                    console.error("Invalid data format received from Directus.");
                }
            } catch (error) {
                console.error("Error fetching settings data from Directus:", error);
            }
        };

        // Tüm verilerin yüklenmesini bekleyin
        Promise.all([fetchCertified(), fetchSettings()]).then(() => {
            setLoading(false); // Veriler tamamen yüklendiğinde loading false olur
        });
    }, []);



    if (certified === null || settings === null) {
        return <Loading />;
    }


    return (
        <React.Fragment>
            <Layout>
                <SEO title={certified?.seo_title} />
                <div className="wrapper">
                    <Header />

                    <PageBanner
                        title={`${certified?.banner_title} `}
                        excerpt={`${certified?.banner_text} `}
                        image={`${baseURL}assets/${certified.banner_img?.id}`}
                    />
                    <br />

                    {settings?.why_us_active === true ? (
                        <AboutContainer />
                    ) : (
                        ""
                        )}

                    {settings?.certified_features_active === true ? (
                        <ServiceListContainer />
                    ) : (
                        ""
                    )}

                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default Certified;
