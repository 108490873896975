import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { baseURL } from "../../../App";
import axios from "axios";



const MainMenu = () => {

    const [menuItems, setMenuItems] = useState([]);


    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const response = await axios.get(`${baseURL}items/header?fields=*.*`)
                if (response.data && Array.isArray(response.data.data)) {
                    setMenuItems(response.data.data);
                } else {
                    console.error("Invalid data format received from Directus.");
                }
            } catch (error) {
                console.error("Error fetching data from Directus:", error);
            }
        };

        fetchMenuItems();
    }, []);




    return (
        <nav className="main-menu d-none d-lg-block">
            <ul className="d-flex">
                {menuItems &&
                    menuItems.map((e) => (
                        <li key={e.id}>
                            <NavLink
                                exact
                                to={e?.link}
                                target={e.target ? "_blank" : "_self"}
                                rel={e.target ? "noopener noreferrer" : ""}
                            >
                                {e?.title}
                            </NavLink>
                        </li>
                    ))}
            </ul>

        </nav>
    );
};

export default MainMenu;
