import React from "react";
import info from "../info";

function Currency({ token, chosenPayment }) {
  return (
    <div
      className="currency-borders mt-1"
      style={{ backgroundColor: token == chosenPayment && "#59e3a7", color: "white", borderRadius: "8px", padding: "10px", border: "1px solid white", width: "120px", }}
    >
      <div className="row">
        <div
          className="col-12"
          style={{ color: token == chosenPayment && "black", justifyContent: "center" }}
        >
          <span style={{ marginRight: "3px", }}>
            <img
              src={token.image}
              alt=""
              style={{ borderRadius: "50%", width: "25px" }}
            />
          </span>
          {token.symbol}
        </div>
      </div>
    </div>
  );
}

export default Currency;
