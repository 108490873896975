import { useState, useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";
import { baseURL } from "../../App";
import axios from "axios";



const AboutContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);



    const [certified, setCertified] = useState([]);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/certified?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setCertified(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div className="about-content mt-4">
                            <SectionTitle
                                classOption="title-section"
                                subTitle={`${certified?.why_us_title} `}
                                title=""
                            />
                            <h4 className="text-white mb-3" >
                                {`${certified?.why_us_text} `}
                            </h4>
                            {/* <span className="date-meta">{`${certified?.content} `}</span> */}
                            <p className="mb-5">{`${certified?.why_us_content} `}</p>
                            {/* <p className="high-light mb-8">{`${certified?.title} `}</p> */}
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text={certified?.button_text2}
                                path={certified?.button_link2}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${baseURL}assets/${certified.img?.id}`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container mt-8 pt-8">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div className="about-content mt-4">
                            <SectionTitle
                                classOption="title-section"
                                subTitle={`${certified?.title} `}
                                title=""
                            />
                            {/* <span className="date-meta">
                                {`${certified?.text} `}
                            </span> */}
                            {/* <span className="date-meta">{`${certified?.content} `}</span> */}
                            <p className="mb-5">{`${certified?.content} `}</p>
                            {/* <p className="high-light mb-8">{`${certified?.title} `}</p> */}
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text={certified?.button_text}
                                path={certified?.button_link}
                            />
                        </div>
                    </div>

                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${baseURL}assets/${certified.img2?.id}`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutContainer;
