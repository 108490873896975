import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import BlogDetailsContainer from "../containers/blog/blog-details";
import BlogData from "../data/blog.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { baseURL } from "../App";
import axios from "axios";
import { Link } from "react-router-dom";



const BlogDetailsPage = () => {




    const [blogpages, setblogpages] = useState([]);
    const [lastPosts, setlastPosts] = useState(null);


    useEffect(() => {
        const getinfo = async () => {
            const inf = await axios.get(
                `${baseURL}items/blog_page?fields=*.*`
            );
            setblogpages(inf.data.data);
            const last = await axios.get(
                `${baseURL}items/blogs?fields=*.*&limit=3&sort=-date_created`
            );

            setlastPosts(last.data.data);
        };
        getinfo();
    }, []);




    return (
        <React.Fragment>
            <Layout>
                <SEO title={blogpages?.seo_title} />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={blogpages?.details_title}
                        excerpt={blogpages?.details_text}
                        image={`${blogpages}assets/${blogpages?.details_img?.id}`}
                    />
                    <BlogDetailsContainer data={blogpages} />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};


export default BlogDetailsPage;
