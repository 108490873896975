import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import IconBox from "../../../components/icon-box";
import SectionTitle from "../../../components/section-title";
import HomeData from "../../../data/home.json";
import { baseURL } from "../../../App";
import axios from "axios";
import { Link } from "react-router-dom";



const IconBoxContainer = ({ classOption }) => {


    const [intro, setIntro] = useState([]);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/intro?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setIntro(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);


    return (
        <div className={`feature-section mt-6 position-relative ${classOption}`}>
            <img
                className="path-img"
                src={`${process.env.PUBLIC_URL}/images/feature/shape.png`}
                alt="images_not_found"
            />
            <div className="container custom-container pt-6">
                <div className="row g-0 align-items-center">
                    <div className="col-xl-6 col-lg-8 mx-auto mb-3 mt-6 text-center">
                        <SectionTitle
                            classOption="title-section"
                            // subTitle="Here’s a simple explanation you can offer:"
                            title={intro?.title}
                            excerptClassOption="mb-10"
                            excerpt={intro?.text}
                        />
                        {/* <Button
                            classOption="btn btn-lg btn-dark btn-hover-dark"
                            text="Get Started"
                            path="/contact"
                        /> */}
                    </div>

                    <div className="col-12">
                        <div id="grid" className="grid row mb-n7">
                            {/* {HomeData[2].iconBox &&
                                HomeData[2].iconBox.map((single, key) => {
                                    return <IconBox key={key} data={single} />;
                                })} */}



                            <div className="grid-item mb-7">
                                <div className="feature-card bg-light">
                                    <span className="card-shape card-shape-light"></span>
                                    <span className="card-shape card-shape-dark"></span>
                                    {/* <img
                                        className="logo"
                                        src={process.env.PUBLIC_URL + data.icon}
                                        alt=" feature logo"
                                    /> */}
                                    <h4 className="title my-6">
                                        {intro?.box_title1}
                                    </h4>
                                    <p style={{ color: "#000" }}>{intro?.box_text1}</p>
                                </div>
                            </div>


                            <div className="grid-item card-mt-75 mb-7">
                                <div className="feature-card bg-light">
                                    <span className="card-shape card-shape-light"></span>
                                    <span className="card-shape card-shape-dark"></span>
                                    {/* <img
                                        className="logo"
                                        src={process.env.PUBLIC_URL + data.icon}
                                        alt=" feature logo"
                                    /> */}
                                    <h4 className="title my-6">
                                        {intro?.box_title2}
                                    </h4>
                                    <p style={{ color: "#000" }}>{intro?.box_text2}</p>
                                </div>
                            </div>



                            <div className="grid-item mb-7">
                                <div className="feature-card bg-light">
                                    <span className="card-shape card-shape-light"></span>
                                    <span className="card-shape card-shape-dark"></span>
                                    {/* <img
                                        className="logo"
                                        src={process.env.PUBLIC_URL + data.icon}
                                        alt=" feature logo"
                                    /> */}
                                    <h4 className="title my-6">
                                        {intro?.box_title3}
                                    </h4>
                                    <p style={{ color: "#000" }}>{intro?.box_text3}</p>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};

// IconBoxContainer.propTypes = {
//     classOption: PropTypes.string,
// };

// IconBoxContainer.defaultProps = {
//     classOption: "section-pb",
// };

export default IconBoxContainer;
