import React from "react";
import info from "../info";

function Input({ token, value, setValue, disabled }) {
  return (
    <div className="token-input">
      <div className="row">
        <div className="col-10">
          <input
            type="number"
            className="token-input-input"
            disabled={disabled}
            value={value}
            placeholder="Enter amount in"
            onChange={(e) => setValue(e.target.value)}
            style={{
              border: "2px solid #59e3a7",
              backgroundColor: "#fff",
              borderRadius: "15px",
              width: "100%",
              padding: "10px",
              color: "black"
            }}
          />
        </div>
        <div
          className="col-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img
            src={token.image}
            alt=""
            className="token-image mt-1"
            style={{
              width: "10vw", 
              height: "10vw", 
              maxWidth: "40px", 
              maxHeight: "40px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />


        </div>
      </div>
    </div>
  );
}

export default Input;
