import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Presale from "../components/Presale";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import NewsletterArea from "../containers/global/newsletter";
import TeamContainer from "../containers/global/team";
import TestimonialContainer from "../containers/global/testimonial";
import HomeAboutContainer from "../containers/home/about";
import HomeBlog from "../containers/home/blog";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import AccordionContainer from "../containers/accordion";
import Loading from "../components/Loading";
import axios from "axios";
import { baseURL } from "../App";


const HomePage = () => {


    const [content, setcontent] = useState(null);

    useEffect(() => {
        const fetchConstants = async () => {
            try {
                const response = await axios.get(`${window.location}custom.json`);
                setcontent(response.data);
            } catch (error) {
                console.error("Error loading constants:", error);
            }
        };

        fetchConstants();
    }, []);

    const [settings, setSettings] = useState(null);


    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/settings?fields=*.*`)
                .then((response) => {
                    if (response.data) {
                        setSettings(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);




    const [genaralsettings, setgenaralsettings] = useState([]);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${baseURL}items/genaral_settings?fields=*.*`);
                if (response.data) {
                    setgenaralsettings(response.data.data);
                } else {
                    console.error("Invalid data format received from Directus.");
                }
            } catch (error) {
                console.error("Error fetching data from Directus:", error);
            } 
        };

        fetchSettings();
    }, []);



    if (!content) return <Loading />;



    return (
        <React.Fragment>
            <Layout>
                <SEO title={genaralsettings?.title} />
                <div className="wrapper">
                    <Header />
                    {settings?.banner_active === true ? (
                        <IntroContainer />
                    ) : (
                        <div
                            style={{
                                marginTop: "120px"
                            }}></div>
                    )}

                    {settings?.presale_active === true ? (
                        <Presale />
                    ) : (
                        <div
                            style={{
                                marginTop: "120px"
                            }}></div>
                    )}


                    {settings?.intro_active === true ? (
                        <IconBoxContainer />
                    ) : (""
                    )}

                    {settings?.features_active === true ? (
                        <ServiceListContainer />
                    ) : (
                        ""
                    )}

                    {settings?.faq_active === true ? (
                        <AccordionContainer />
                    ) : (
                        ""
                    )}

                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HomePage;
