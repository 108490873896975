import React, { useState, useEffect } from "react";
import { baseURL } from "../../App";
import axios from "axios";
import AccordionWrap from "../../components/accrodion/AccordionWrap";

const AccordionContainer = () => {

    const [faqarea, setFaqarea] = useState([]);



    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/faq_area?fields=*.*`)
                .then((response) => {
                    // Verinin doğru formatta olup olmadığını kontrol et
                    if (response.data) {
                        // Veriyi state'e set etme
                        setFaqarea(response.data.data);
                    } else {
                        console.error("Invalid data format received from Directus.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    return (
        <div className="faq-section section-py">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 text-center m-3 p-3">
                        <h3>{faqarea.title}</h3>
                    </div>
                    <div className="col-xl-12">
                        <AccordionWrap />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccordionContainer;
