import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "../button";
import Parallax from "parallax-js";
import { baseURL } from "../../App";
import axios from "axios";



const Intro = ({ data }) => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);




    const [banner, setBanner] = useState([]);

    useEffect(() => {
        try {
            axios
                .get(`${baseURL}items/banner?fields=*.*`)
                .then((response) => {
                    setBanner(response.data.data);
                })
                .catch((error) => {
                    console.error("Error fetching data from Directus:", error);
                });
        } catch (error) {
            console.error("An error occurred in useEffect:", error);
        }
    }, []);



    return (
        <div className="hero-slider">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        {banner && banner && (
                            <div className="hero-slide-content">
                                <h2 className="title animated mt-3" style={{ fontSize: '40px' }}>{banner?.title}</h2>
                                <h3 className=" animated mb-4" style={{ fontSize: '20px', color: "#fff" }}>{banner?.text}</h3>
                                <Button
                                    classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                    text={banner?.button_text}
                                    path={banner?.button_link}
                                />
                            </div>
                        )}

                    </div>
                    <div className="col-lg-6">
                        <div
                            className="hero-img scene mt-10 mt-lg-0"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    className="animated mr-8 mb-8"
                                    src={`${baseURL}assets/${banner.img?.id}`} alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default Intro;
